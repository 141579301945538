import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Nav from './components/Nav/index';
import NavMobile from './components/Nav/index.mobile';
import styles from './index.module.less';
import {ClassNames} from '@/utils';

const Header = inject(
  'app', 'auth', 'gold'
)(
  observer( (props) => {
    const { app: { isMobile }, auth: {alreadyAuth} } = props;

    useEffect(()=>{
      alreadyAuth && props.gold.getTotalPoints()
    },[alreadyAuth])

    return (
      <div id='g-header' className={styles.gHeader}>
        <div className={ClassNames([styles.content, styles.fixed])}>
          {isMobile ? <NavMobile/> : <Nav/>}
        </div>
      </div>
    );
  })
);

export default Header;
