import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, message } from 'antd';
import validator from '@/utils/validator';
import styles from './index.module.less';

@inject('area', 'auth', 'app')
@observer
class Subscribe extends Component {
  state = {
    email: ''
  };

  submit = () => {
    const {
      area: { LANG },
      auth: { emailSubscribe }
    } = this.props;
    const { email } = this.state;
    if (!email) return;
    const checkEmail = validator({ type: 'email', val: email });
    if (!checkEmail) return message.warning(LANG['common.form.emailMsg']);
    emailSubscribe({
      email
    }).then((res) => {
      if (res.code === 0) {
        message.success(LANG['common.tips.submittedSuccessfully']);
      } else if (res.code === 70001) {
        message.error(LANG['common.tips.subscribeRepeatMsg']);
      }
    });
  };

  render () {
    const {
      area: { LANG }
    } = this.props;

    return (
      <div className={styles.Subscribe}>
        <Input
          className={styles.input}
          placeholder={LANG['common.form.subscribePlaceholder']}
          onInput={(e) => this.setState({ email: e.target.value.trim() })}
        />
        <button className={styles.submit} onClick={this.submit}>
          {LANG['common.btn.subscribe']}
        </button>
      </div>
    );
  }
}

export default Subscribe;
