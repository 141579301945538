import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SearchInput, { createFilter } from 'react-search-input';
import { ICON } from '@/utils/configs/static.config';
import MiddleModal from '@/components/MiddleModal';
import { cache } from '@/utils';
import styles from './index.module.less';

@inject('area')
@observer
class Area extends Component {
  state = {
    searchText: ''
  };

  handleSelect = (country) => {
    const { toggleAreaModal, setCountry } = this.props.area;
    toggleAreaModal(false);
    setCountry(country);
  };

  handleChange = (searchText) => {
    this.setState({ searchText });
  };

  resetSearch = () => {
    this.setState({ searchText: '' });
  };

  render () {
    const { searchText } = this.state;
    const { LANG, areaModalVisible, toggleAreaModal, areaMap } = this.props.area;
    const AREA_GROUP_ENUM = {
      'COMMON': LANG['area.groupCommon'],
      'AS': LANG['area.groupAP'],
      'EU': LANG['area.groupEU'],
      'NA': LANG['area.groupAM'],
      'ME': LANG['area.groupME']
    };
    const areas = [];
    areaMap.forEach((areaGroup) => {
      if (areaGroup.name !== 'COMMON') {
        return areaGroup.list.forEach((area) => {
          areas.push(area);
        });
      }
    });
    const filterAreas = areas.filter(createFilter(searchText, [ 'name' ]));
    return (
      <MiddleModal
        title={LANG['area.chooseCountry']}
        visible={areaModalVisible}
        wrapClassName='area-selected-modal'
        closable={cache.getCookie('X_Country_User_Select') !== '1'}
        width={952}
        onCancel={toggleAreaModal}
      >
        <div className={styles.area}>
          <p className={styles.subTitle}>{LANG['area.tips']}</p>

          <div className={`${styles.search} ${styles.areaGroup}`}>
            <div className={styles.searchInputWrap}>
              <SearchInput
                className={styles.searchInput}
                onChange={this.handleChange}
                value={searchText}
                placeholder={LANG['common.btn.search']}
                fuzzy
              />
              {searchText && (
                <img src={ICON.closeBlack} alt='' className={styles.searchReset}
                     onClick={this.resetSearch}/>
              )}
            </div>

            {searchText && filterAreas.length > 0 && (
              <div className={styles.areaGroupList}>
                {filterAreas.map((area, index) => (
                  <button className={styles.item} key={index}
                          onClick={this.handleSelect.bind(this, area)}>
                    <div className={styles.text}>
                      {`${area.name} ( ${area.language_text} / ${area.currency_signal} ${area.currency} )`}
                    </div>
                  </button>
                ))}
              </div>
            )}
            {searchText && filterAreas.length === 0 &&
            <div className={styles.notFound}>{LANG['common.label.notFound']}</div>}
          </div>

          {areaMap &&
          areaMap.map((areaGroup, index) => (
            <div className={styles.areaGroup} key={index}>
              <div
                className={styles.areaGroupName}>{AREA_GROUP_ENUM[areaGroup.name]}</div>
              <div className={styles.areaGroupList}>
                {areaGroup.list.map((area, index2) => (
                  <button className={styles.item} key={index2}
                          onClick={this.handleSelect.bind(this, area)}>
                    <div className={styles.text}>
                      {`${area.name} ( ${area.language_text} / ${area.currency_signal} ${area.currency} )`}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </MiddleModal>
    );
  }
}

export default Area;
