import React, { Component } from 'react';
import { Modal } from 'antd';
import { inject } from 'mobx-react';
import { ClassNames } from '@/utils';
import styles from './index.module.less';

@inject('area')
class InstaModal extends Component {
  handleCancel = () => {
    document.body.removeAttribute('data-HideCustomerService');
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  render () {
    const {
      onConfirm,
      maskClosable,
      hideHeaderBorder = false,
      centered = true,
      visible,
      title,
      headerVisible = true,
      closable = true,
      children,
      wrapClassName,
      cancelText,
      confirmText,
      showBtnCt,
      showCancel = true,
      showConfirm = true,
      width = '480px',
      theme,
      titleAlign = 'left',
      titleBorder = true
    } = this.props;
    const { LANG } = this.props.area;
    if (typeof window === 'undefined') return null;
    if (visible) {
      document.body.setAttribute('data-HideCustomerService', 'true');
    } else {
      document.body.removeAttribute('data-HideCustomerService');
      return null;
    }
    return (
      <Modal
        visible={visible}
        width={width}
        closable={false}
        maskClosable={maskClosable || false}
        footer={null}
        wrapClassName={`${styles.MiddleModal} ${wrapClassName || ''} ${theme ===
        'float' ? styles.float : ''}`}
        title=''
        centered={centered}
        onCancel={this.handleCancel}
      >
        <div className={styles.main}>
          {headerVisible && (
            <div
              className={ClassNames([
                styles.header,
                !title ? styles.hideTitle : '',
                titleAlign === 'left' ? '' : styles.center,
                titleBorder ? '' : styles.noBorder
              ])}
              data-hide-border={hideHeaderBorder}
            >
              <div className={styles.title}>{title || ''}</div>
              {closable && (
                <button className={styles.btnClose} onClick={this.handleCancel}>
                  {' '}
                  ×{' '}
                </button>
              )}
            </div>
          )}
          <div className={styles.content} data-hide-border={hideHeaderBorder}>
            {children}
          </div>
          {showBtnCt && (
            <div className={styles.footer}>
              {showCancel && (
                <button onClick={this.handleCancel}
                        className={styles.btnCancel}>
                  {cancelText || LANG['common.btn.cancel']}
                </button>
              )}
              {showConfirm && (
                <button onClick={onConfirm} className={styles.btnConfirm}>
                  {confirmText || LANG['common.btn.confirm']}
                </button>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default InstaModal;
