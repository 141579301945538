import React from 'react';
import styles from './index.module.less';

const Menu = (props) => {
  const { menusGroup } = props;
  return (
    <div className={styles.footerLinks}>
      {menusGroup.map((item, i) => (
        <div className={styles.groupItem} key={i}>
          <span className={styles.itemTitle}>{item.key}</span>
          <ul className={styles.links}>
            {item.val.map((link, j) => (
              <li className={styles.link} key={j}>
                <a href={link.url} target='_blank' rel='noopener noreferrer'>
                  {link.key}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Menu;
