import React, { Component } from 'react';
import { scrollToSmooth } from '@/utils';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';

@inject('area', 'app')
@observer
class Index extends Component {
  constructor (props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handlePageScrollListener);
  }

  handlePageScrollListener = () => {
    const visibleHeight = this.props.app.isMobile ? 1000 : 1500;
    if (window.pageYOffset >= visibleHeight) {
      if (!this.state.visible) this.setState({ visible: true });
    } else {
      if (this.state.visible) this.setState({ visible: false });
    }
  };

  handleBackTop = () => {
    scrollToSmooth(0, 0, 'instant');
  };

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handlePageScrollListener);
  }

  render () {
    const {
      country: { code }
    } = this.props.area;
    const {
      isMobile
    } = this.props.app;
    const { visible } = this.state;
    return (
      visible && (
        <button
          style={!isMobile ? { bottom: 358 } : {}}
          id='BackTop'
          data-country={code}
          className={styles.BackTop}
          onClick={this.handleBackTop}
        />
      )
    );
  }
}

export default Index;
