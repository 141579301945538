import qs from 'qs';
import Cache from './libs/Cache';
import Cookies from 'js-cookie';

/**
 * 获取用户Token
 */
function getToken() {
  const token = Cookies.get('insta_access_token');
  return token || '';
}

/**
 * 获取cache实例
 * @param  {null}
 * @return {Object} cacheInstance
 */
let cacheInstance = null;

function getCache() {
  if (cacheInstance) {
    return cacheInstance;
  } else {
    cacheInstance = new Cache();
    return cacheInstance;
  }
}

const cache = getCache();

const isBrowser = (() => typeof window !== 'undefined')();

/**
 *
 * @desc: 判断是否微信浏览器环境
 * @return {boolean} value
 * @time: 2019-06-03 17:55
 *
 */
function isWechat() {
  return typeof window !== 'undefined' ? /micromessenger/.test(navigator.userAgent.toLowerCase()) : false;
}

/*
 *
 * @desc: 获取url参数值
 * @param {string} variable
 * @return {string} value
 * @time: 2019-06-03 18:12
 *
 */
function getQueryVariable(variable) {
  var query = typeof window !== 'undefined' ? window.location.search.substring(1) : '';
  var vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
}

/*
 *
 * @desc: 去除字符串空格
 * @param {string} str
 * @return {string} str
 * @time: 2019-06-03 17:38
 *
 */

function strTrim(str) {
  return str ? str.replace(/\s/g, '') : '';
}

/**
 * 正则提取传入富文本去掉标签后的内部文字，可用于判断是否为空
 */
function getRichText(richText) {
  // 可用于匹配 <img/>、<audio></audio>、<video></video>的标签及内容
  const reg1 = new RegExp('<(img|audio|video)(S*)[^>]*>', 'gi');
  const reg2 = new RegExp('<[^>]+>', 'gi');
  const text = richText.replace(reg2, '');

  return text;
}

/**
 * 判断传入数据是否为空 为空时isEmpty() = true
 * @param strictFlag 是否严格比对，传入true时string会将空格、richText的空标签也视为空
 * @description undefined \ null \ '' \ boolean \ [] \ {} 判断为空。
 */
function isEmpty(value, strictFlag = false) {
  if (strictFlag && typeof value === 'string') {
    value = getRichText(value);
    value = value.replace(/\s*/g, '');
  }
  if (value === undefined || value === null || value === '' || typeof value === 'boolean') {
    return true;
  } else if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return JSON.stringify(value).replace(/\s*/g, '') === '{}';
    }
  }

  return false;
}

/*
 * scrollToSmooth
 * @desc:平滑滚动
 * @param {number} top
 * @param {number} left
 * @param {string} behavior 'auto' | 'smooth' | 'instant'
 * @time: 2019-06-27 11:01
 *
 */
function scrollToSmooth(top, left = 0, behavior = 'smooth') {
  window.scrollTo({
    top,
    left,
    behavior,
  });
}

/**
 *
 * @desc: 添加|修改|删除url参数
 * @param {string} key 参数名
 * @param {string} value 参数值
 * @return {string} href
 * @time: 2020/4/2 10:14
 *
 */
function handleQueryParams(key, value) {
  const { origin, pathname, search } = window.location;
  const query = qs.parse(search.substr(1));
  let href = typeof window !== 'undefined' ? window.location.href : '';
  if (key) {
    if (value) {
      query[key] = value;
    } else {
      delete query[key];
    }
    const qsStr = qs.stringify(query);
    href = `${origin}${pathname}${qsStr ? '?' + qsStr : ''}`;
  }
  return href;
}

/**
 *
 * @desc: 函数防抖
 * @param {Function} fn
 * @param {Number} delay
 * @return {Function} function
 * @time: 2020/4/11 11:16
 *
 */

function debounce(fn, delay) {
  let timeout;
  return function () {
    let ctx = this;
    let args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(ctx, args);
    }, delay);
  };
}

/**
 *
 * @desc: 函数节流
 * @param {Function} fn
 * @param {Number} threshold
 * @return {type} value
 * @time: 2020/4/11 12:11
 *
 */
function throttle(fn, threshold) {
  let timeout;
  let start = +new Date();
  return function () {
    const ctx = this;
    const args = arguments;
    const curr = +new Date();
    clearTimeout(timeout);
    if (curr - start >= threshold) {
      fn.apply(ctx, args);
      start = curr;
    } else {
      timeout = setTimeout(function () {
        fn.apply(ctx, args);
      }, threshold);
    }
  };
}

/**
 *
 * @desc: 轮询获取dom节点
 * @param {string} dom DOM节点
 * @return {type} value
 * @time: 2019-06-20 11:21
 *
 */
function querySelector(dom) {
  return new Promise((resolve, reject) => {
    let counter = 0;
    const MAX_COUNTER = 50;
    if (typeof document === 'undefined') return;
    const timer = setInterval(() => {
      const DOM = document.querySelector(dom);
      counter++;
      if (DOM) {
        resolve(DOM);
        clearInterval(timer);
      }
      if (counter > MAX_COUNTER) {
        clearInterval(timer);
      }
    }, 100);
  });
}

/**
 *
 * @desc: 拼接样式名
 * @param {array} group
 * @return {string} value
 * @time: 2021/1/25 18:26
 *
 */
function ClassNames(group) {
  return group.filter((o) => o).join(' ');
}

/**
 *
 * @description 获取跳转链接，
 * @param {string} secDomain 二级域名
 * @param {string} pathName 跳转路径
 * @param {object} appState app mobx state
 * @return {string} pathName
 * @time 2022/6/20 17:13
 *
 */
function handleInstaLink(secDomain, pathName, appState) {
  const { isProd } = appState;
  switch (secDomain) {
    case 'www':
      return `https://www${!isProd ? '-dev' : ''}.insta360.com${pathName}`;
    case 'store':
      return `https://store${!isProd ? '-test' : ''}.insta360.com${pathName}`;
    default:
      return pathName;
  }
}

/**
 *
 * @description 数组（对象）排序
 * @param {string} key 自定义排序字段
 * @param {boolean} desc 默认降序
 * @return {array} list
 * @time 2022/6/20 17:13
 *
 */
Array.prototype.sortByCS = function (key = '', desc = true) {
  const isArrayObject = typeof this[0] === 'object' && !!key;
  return this.sort((prev, next) => {
    const prevVal = isArrayObject ? prev[key] : prev;
    const nextVal = isArrayObject ? next[key] : next;
    if (prevVal > nextVal) {
      return desc ? -1 : 1;
    } else if (prevVal < nextVal) {
      return desc ? 1 : -1;
    } else {
      return 0;
    }
  });
};

export {
  isBrowser,
  cache,
  isWechat,
  getQueryVariable,
  strTrim,
  scrollToSmooth,
  handleQueryParams,
  debounce,
  throttle,
  ClassNames,
  getRichText,
  isEmpty,
  querySelector,
  handleInstaLink,
  getToken,
};
