import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { IMG } from '@/utils/configs/static.config';
import styles from './index.module.less';
import dynamic from 'next/dynamic';
import { handleInstaLink } from '@/utils';
import { useRouter } from 'next/router';

const Personal = dynamic(import('@/components/Header/components/Personal'), { ssr: false });

const Nav = inject(
  'area',
  'auth',
  'gold',
  'app'
)(
  observer(({ area, auth, gold, app }) => {
    const router = useRouter();
    const { LANG, toggleAreaModal, country } = area;
    const { alreadyAuth } = auth;
    const { userPoint } = gold;
    const [showUserPoint, setShowUserPoint] = useState(false);
    useEffect(() => {
      setShowUserPoint(alreadyAuth && !isNaN(userPoint));
    }, [alreadyAuth, userPoint]);

    return (
      <div className={styles.header}>
        <div className={styles.wrap}>
          <a href='/' className={styles.brand}>
            <img className={styles.logo} src={IMG.GoldMall.logo} alt='' />
          </a>
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <a href='/'>
                <span
                  className={`
                  ${styles.itemText} 
                  ${router.asPath === '/' ? styles.active : ''}
                  `}
                >
                  {LANG['header.goldStore']}
                </span>
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href={handleInstaLink('store', '/', app)} target='_blank'>
                <span className={styles.itemText}>{LANG['header.officialStore']}</span>
              </a>
            </li>
          </ul>
          <div className={styles.userMenu}>
            {showUserPoint && (
              <div className={styles.totalPoints}>
                <img src='https://res.insta360.com/static/8dc5705e9ae5626b71d96faca71dc9cd/gold-icon.png' alt='' />
                {userPoint} {LANG['common.label.gold']}
              </div>
            )}
            <div className={styles.auth}>
              <Personal />
            </div>
            <span className={styles.area} onClick={toggleAreaModal}>
              {country.name}
              {` ( ${country.language_text} / ${country.signal} ${country.currency} )`}
            </span>
          </div>
        </div>
      </div>
    );
  })
);

export default Nav;
