import isEmail from 'validator/lib/isEmail';

/**
 * @function
 * @description 数据校验，依赖按需引入，目前只引入邮箱校验
 * @param {String} type 校验数据的类型
 * @param {String} val 校验数据的值
 * @param {Object} extra 额外参数
 *                 extra.allCountry 所有国家地区列表
 * @author haifn
 * @time: 2021-11-8 5:51:37
 *
 */
function validator({ type, val, extra = {} }) {
  switch (type) {
    case 'email':
      return isEmail(val);
    case 'invoiceTitle':
      // 发票抬头：发票抬头仅允许：汉字、字母、数字、-、（）、空格
      return /^[a-zA-Z0-9（）\s\-\u4E00-\u9FA5]+$/.test(val);
    case 'phoneCode':
      // 长度大于6 || phoneCode不在我们自己库中的均为不合法
      if (!val || val.length > 6) return false;
      return extra.allCountry.some((e) => parseInt(e.phoneCode) === parseInt(val));
    case 'password':
      return val.trim().length >= 8;
    default:
      return false;
  }
}

export default validator
