const BASE_PRODUCTION = 'https://service.insta360.com';
const BASE_TEST = 'https://service-test.insta360.com';

const API_SERVER = {
  STORE: '/store-service/store',
  ACCOUNT: '/infr-user-service/account/v1',
  CREDIT: '/infr-point-service/credit',
  POINT: '/infr-point-service',
};

/**
 *
 * @description 处理url
 * @param {string} hostKey Host前缀
 * @param {string} path url路径
 * @param {object} appStore mobx app store
 * @time 2022-06-20 5:16:9
 */
function getServerUrl(hostKey = '', path = '', appStore) {
  const { isProd } = appStore;
  return `${!isProd ? BASE_TEST : BASE_PRODUCTION}${API_SERVER[hostKey]}${path}`;
}

export { getServerUrl };
