import app from './app';
import area from './area';
import auth from './auth';
import gold from './gold';
import { useStore } from './handler';

export function rootStoreHandle({ initialState }) {
  const stores = {
    area,
    app,
    auth,
    gold,
  };
  for (const key in stores) {
    if (initialState && initialState[key]) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      stores[key] = useStore(key, stores[key], initialState[key]);
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      stores[key] = useStore(key, stores[key]);
    }
  }
  return stores;
}
