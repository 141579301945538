import { action, computed, makeObservable, observable } from 'mobx';
import { httpRequest } from '@/request';
import { getServerUrl } from '@/request/config';
import { cache } from '@/utils';
import bootUdesk from '@/utils/uDesk';
import StoreBase from './Base';
import { enableStaticRendering } from 'mobx-react';

enableStaticRendering(typeof window === 'undefined');

class State extends StoreBase {
  constructor (props) {
    super(props);
    makeObservable(this);

    if (typeof window !== 'undefined') {
      this.initUDeskOrIntercom();
      this.instaToken = cache.getCookie('insta_access_token');
      this.instaToken && this.getProfile()
    }
  }

  @observable
  account = null;

  @observable
  instaToken = '';

  // 登录用户邮箱
  @observable
  email = '';

  @computed
  get alreadyAuth () {
    return this.instaToken;
  }

  @action
  getProfile = (cb) => {
    httpRequest({
      url: getServerUrl('ACCOUNT', '/getProfileByToken', this.root.app),
      instaToken: true,
      country: this.root.area.country,
    }).then((res) => {
      if (res.code === 0) {
        this.email = res.data.account.email;
        window.cpaE = res.data.account.email;
        this.account = res.data.account;

        // alife-logger
        const timer = setInterval(() => {
          if (typeof window !== 'undefined' && window?.__bl?.setConfig) {
            window.__bl.setConfig({
              uid: this.email
            });
            console.log('bootAliLog', this.email);
            clearInterval(timer);
          }
        }, 250);

        this.initUDeskOrIntercom({ email: this.email });
      }
    });
  };

  /**
   *
   * @desc: 邮箱订阅
   * @author: haifn
   * @time: 2021-11-22 7:1:34
   */
  @action
  emailSubscribe = (data, cb) => {
    let LocationPath = window.location.pathname;

    function handleActivityPage(){
      // 从外部传进来则取参数值
      if (data.activityPage) return data.activityPage
      // 否则取默认配置
      const storeSubscriptionMap = {
        '': 'gold-store',
      };
      // 移除结尾的斜杠字符
      if (LocationPath[LocationPath.length - 1] === '/') {
        LocationPath = LocationPath.slice(0, LocationPath.length - 1);
      }
      return storeSubscriptionMap[LocationPath] || 'other_footer';
    }

    function handleSubscribePage(){
      return LocationPath
    }

    function handleGoogleField(){
      const zc_gad = document.getElementById('zc_gad');
      return zc_gad ? zc_gad?.value : ''
    }

    const postData = {
      email: data.email,
      activityPage: handleActivityPage(),
      subscribePage:handleSubscribePage(),
      googleField: handleGoogleField()
    };

    return httpRequest({
      url: getServerUrl('STORE', '/user/subscribe/submitSubscribe', this.root.app),
      method: 'post',
      country: this.root.area.country,
      data: postData,
    });
  };

  @action
  logout = () => {
    this.instaToken = '';
    cache.removeCookie('insta_access_token');
    cache.removeCookie('insta_access_token', { domain: '.insta360.com' });
    cache.removeCookie('insta_store_token');
    cache.removeCookie('insta_store_token', { domain: '.insta360.com' });
    window.location.reload();
  };

  /**
   *
   * @desc: 初始化客服工具客户联系方式
   *
   */
  initUDeskOrIntercom = (props = {}) => {
    const { code } = this.root.area.country
    const { email = '' } = props;
    if (!email) return '';
    if (code === 'CN') {
      bootUdesk(email);
    } else {
      console.log('intercom boot：', email);
      window.Intercom &&
      window.Intercom('boot', {
        app_id: 'uoyosxqi',
        email: email || ''
      });
    }
  };
}

export default State;
