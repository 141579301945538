import React from 'react';
import { inject } from 'mobx-react';
import styles from './index.module.less';

const Copyright = (props) => {
  const {
    area: { LANG, country, toggleAreaModal },
    app: { isMobile }
  } = props;

  const isCN = country.code === 'CN';

  return (
    <div className={styles.Copyright}>
      {!isMobile &&
      <span className={styles.icp}>{LANG['common.label.copyright']}</span>}
      {!isMobile && isCN && <a href='/business_license' target='_blank'
                               className={styles.businessLicense}>营业执照</a>}
      {country && (
        <span className={styles.changArea} onClick={()=>toggleAreaModal(true)}>
          {`${country.name}（${country.language_text} / ${country.signal}${country.currency}）`}
        </span>
      )}

      {isMobile &&
      <div>
        <span className={styles.icp}>{LANG['common.label.copyright']}</span>
        {isCN &&
        <a href='/business_license' className={styles.businessLicense}>营业执照</a>}
      </div>
      }
    </div>
  );
};

export default inject('area', 'app')((props) => <Copyright {...props} />);
