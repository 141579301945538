import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { ICON, IMG } from '@/utils/configs/static.config';
import styles from './index.mobile.module.less';
import dynamic from 'next/dynamic';
import { ClassNames, handleInstaLink } from '@/utils';

const Personal = dynamic(import('@/components/Header/components/Personal'), { ssr: false });

const NavMobile = inject('area')((props) => {
  return (
    <div className={styles.headerMobile}>
      <div className={styles.wrap}>
        <Menu />
        <a href='/' className={styles.logo}>
          <img src={IMG.GoldMall.logo} alt='' />
        </a>
        <Personal />
      </div>
    </div>
  );
});

const Menu = inject(
  'area',
  'app'
)(({ area, app }) => {
  const [visible, setVisible] = useState(false);
  const { LANG } = area;

  const toggleVisible = (visible) => {
    setVisible(visible);
  };

  return (
    <div className={styles.menu} onMouseLeave={() => toggleVisible(false)}>
      <button className={styles.menuBtn} onClick={() => toggleVisible(!visible)}>
        <img
          src={
            visible
              ? ICON.closeBlack
              : 'https://res.insta360.com/static/4886179d353a54485ea6b8d8e81a8ab5/Frame%201274.svg'
          }
        />
      </button>
      <div className={ClassNames([styles.menuList, visible ? styles.visible : ''])}>
        <a className={styles.item} href='/' target='_self'>
          {LANG['header.goldStore']}
        </a>
        <a className={styles.item} href={handleInstaLink('store', '/', app)} target='_self'>
          {LANG['header.officialStore']}
        </a>
      </div>
    </div>
  );
});

export default NavMobile;
