import React, { useState } from 'react';
import { ClassNames, isEmpty } from '@/utils';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';
import { getSocialList } from './const/data.config';

const logo = 'https://res.insta360.com/static/a6504c3224ad43b71b795f319fe0de16/barnd-en.svg';

const Social = ({ area, app }) => {
  const { isMobile } = app;
  const { country } = area;
  const [index, setIndex] = useState(-1);
  return (
    <div className={styles.socialWrap}>
      <a className={styles.logo} href='https://www.insta360.com' target='_blank' rel='noopener noreferrer'>
        <img src={logo} alt='' />
      </a>
      {!isMobile && (
        <div className={styles.social}>
          {getSocialList({ country }).map((social, key) => {
            const hover = index === key;
            return (
              <a
                className={styles.item}
                href={social.link}
                key={key}
                onMouseEnter={() => setIndex(key)}
                onMouseLeave={() => setIndex(-1)}
                target={`${social.link ? '_blank' : ''}`}
                rel='noopener noreferrer'
              >
                <div
                  className={ClassNames([styles.imgWrap])}
                  style={{
                    backgroundImage: `url("${hover ? social.icHover : social.ic}")`,
                  }}
                />
                {!isEmpty(social.popupImg) && (
                  <div className={styles.qrWrap}>
                    <img className={styles.icon} src={social.popupImg} alt='' />
                  </div>
                )}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default inject('area', 'app')(observer((props) => <Social {...props} />));
