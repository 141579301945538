import { useMemo } from 'react'

let storeObj = {}
function initializeStore(key, Store, initialData = null) {
  const _store = storeObj[key] ?? new Store(storeObj)

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store
  }
  // Create the store once in the client
  if (!storeObj[key]) {
    storeObj[key] = _store
  }

  return _store
}

export function useStore(key, Store, initialState) {
  return useMemo(() => initializeStore(key, Store, initialState), [initialState])
}
