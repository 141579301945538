import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MiddleModal from '@/components/MiddleModal';
import { cache } from '@/utils';

require('./index.less');

@inject('area', 'app')
@observer
class Region extends Component {

  state = {
    hasShown: cache.get('region_modal') === '1'
  };

  hideRegion = (action) => {
    cache.set('region_modal', '1');
    this.setState({
      hasShown: true
    });
    if (action === 'changeArea') {
      this.props.area.toggleAreaModal(true);
    }
  };

  render () {
    const { LANG, country } = this.props.area;
    const { isMobile } = this.props.app;
    const { hasShown } = this.state;
    return (
      <MiddleModal
        wrapClassName='global-region-modal'
        title={LANG['area.regionTitle']}
        closable={false}
        onCancel={this.hideRegion.bind(this, 'notChangeArea')}
        visible={isMobile && !hasShown}
      >
        {country && (
          <div className='gsr-country-ct'>
            <div className='country-info'>
              <span className='country-name'>{country.name}</span>
              <span className='country-currency'>
                {`(${country.language_text}/${country.signal}${country.currency})`}
              </span>
            </div>
          </div>
        )}
        <button className='btn-change'
                onClick={this.hideRegion.bind(this, 'changeArea')}>
          {LANG['area.regionChange']}
        </button>

        <button className='btn-stay'
                onClick={this.hideRegion.bind(this, 'notChangeArea')}>
          {LANG['area.regionStay']}
        </button>
      </MiddleModal>
    );
  }
}

export default Region;
