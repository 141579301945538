import React, { Component } from 'react';
import { Spin } from 'antd';

export default class Index extends Component {
  render () {
    const { className } = this.props;
    return (
      <Spin spinning className={className}>
        <div className='container'>
          <div
            style={{
              padding: '40px 0'
            }}
          />
        </div>
      </Spin>
    );
  }
}
