// 基础元信息
const baseMeta = {
  zh_cn: {
    title: 'Insta360影石官方商城 - Insta360 相机，专业相机配件及增值服务（中国大陆）',
    description:
      'Insta360影石官方商城提供自主研发的 ONE R、ONE X、GO等防抖运动相机，Pro 2和Titan等专业VR全景拍摄设备，相机配件，行业应用，Care免费维修服务，以及以旧换新等服务。',
    content: ''
  },
  en_us: {
    title: 'Insta360 Store: The Official Store for Insta360 Cameras, Accessories and Services',
    description:
      'Insta360 is the world\'s leading maker of stablilized action cameras and 360-degree VR cameras. Popular products include ONE R, ONE X, GO, Pro 2, Titan, action sport camera accessories, enterprise solutions, trade-in deals for old action cams and more! Our mission is to help people capture and share their lives with boldly innovative cameras. ',
    content: ''
  },
  zh_tw: {
    title: 'Insta360官方商城 - Insta360 相機，專業相機配件及增值服務',
    description:
      'Insta360影石官方商城提供自主研發的 ONE R、ONE X、GO等防震運動相機，Pro 2和Titan等專業VR全景拍攝設備，相機配件，行業應用，Care免費維修服務，以及以舊換新等服務。',
    content: ''
  },
  ja_jp: {
    title: 'Insta360ストア - Insta360カメラ、アクセサリー、サービス公式ストア',
    description:
      'Insta360は手ブレ補正アクションカメラと360度のVRカメラの世界トップのメーカーです。ONE R、ONE X、GO、Pro2、Titanやカメラアクセサリーなど人気商品の他に、企業向けソリューション、中古アクションカメラの下取りプログラムなども提供しています。我々のミッションは、革新的なカメラで人々が生活を撮影し共有できるようにすることです。',
    content: ''
  },
  de_de: {
    title: 'Insta360 Store: Der offzielle Store für Insta360 Kameras, Zubehör und weitere Dienstleistungen',
    description:
      'Insta360 ist der weltweit führende Hersteller von stabilisierten Action-Kameras und 360-Grad-VR-Kameras. Zu den beliebten Produkten gehören ONE R, ONE X, GO, Pro 2, Titan, Zubehör für Action-Sportkameras, Lösungen für Unternehmen, Angebote zur Inzahlungnahme alter Kameras und vieles mehr!',
    content: ''
  },
  ko_kr: {
    title: 'Insta360 스토어: Insta360 카메라, 액세서리, 서비스 공식 스토어',
    description:
      'Insta360 스토어는 ONE R, ONE X, GO 등 안정화 액션카메라 제품 및 Pro2, Titan 등 프로패셔널 VR 촬영 장비, 카메라 액세서리, 기업 응용, 케어 서비스 제품, 트레이드 업 서비스를 제공하고 있습니다. ',
    content: ''
  },
  es_es: {
    title: 'Insta360 Store: La tienda oficial de Cámaras, Accesorios y Servicios de Insta360',
    description:
      'Insta360 es el fabricante de cámaras estabilizadas de acción y 360 VR. Entre sus productos populares se encuentran la ONE R, la ONE X, la GO, la Pro 2, la Titan, accesorios de cámara deportiva, soluciones empresariales y ¡mucho más! Nuestra misión es ayudar a la gente a capturar y compartir sus vidas con cámaras increíblemente innovadoras.',
    content: ''
  },
  it_it: {
    title: 'Negozio Insta360: Il negozio ufficiale per le Videocamere, gli accessori e i servizi Insta360',
    description:
      'Insta360 è il principale produttore al mondo di action cam stabilizzate e videocamere a 360 gradi VR. I prodotti più popolari includono ONE R, ONE X, GO, Pro 2, Titan, accessori da action cam per gli sport, soluzioni aziendali, offerte di permuta per vecchie action cam e molto di più! La nostra missione è aiutare la gente a catturare e condividere le proprie vite tramite videocamere audacemente innovative.',
    content: ''
  },
  fr_fr: {
    title: 'Insta360 Store: The Official Store for Insta360 Cameras, Accessories and Services',
    description:
      'Insta360 is the world\'s leading maker of stablilized action cameras and 360-degree VR cameras. Popular products include ONE R, ONE X, GO, Pro 2, Titan, action sport camera accessories, enterprise solutions, trade-in deals for old action cams and more! Our mission is to help people capture and share their lives with boldly innovative cameras. ',
    content: ''
  }
};

// 社媒分享封面图
const ogImageMeta = {
  'default': 'https://res.insta360.com/static/assets/storage/20210615/14af54161e1bacc76326ad0dfbbd0d51/default.png'
};

const getMeta = ({ originalUrl, country ={} }) => {
  const language = country.language?.toLowerCase() ?? 'en_us';
  originalUrl = originalUrl?.toLowerCase() ?? '';
  const meta = baseMeta[language];
  meta.ogUrl = `https://store.insta360.com${originalUrl.split('?')[0]}`;
  meta.ogImage = ogImageMeta.default
  return meta;
};

export default getMeta;
