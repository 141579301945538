const BASE_STORAGE = 'https://static.insta360.com/assets/storage';
const BASE_OPERATION = 'https://static.insta360.com/assets/operation';
const BASE_WWW = 'https://static.insta360.com/assets/www';

export const IMG = {
  home: {
    prevIc: BASE_STORAGE + '/20190615/cdccc121e772a2a3435d34b81a1ad037/ic_last@2x.png',
    nextIc: BASE_STORAGE + '/20190615/5878aef75c0448b681957e7d54fddb33/ic_next@2x.png',
    iPayment: BASE_STORAGE + '/20190225/6e5b847d297d622eecf531eddb69f3a1/payment@2x.png',
    iService: BASE_STORAGE + '/20190225/91cfa6ce26c3696266d76b9249d3cb57/service@2x.png',
    iShipping: BASE_STORAGE + '/20190225/92720eabc8b9cffc8bbcd9ed91242a30/shipping@2x.png',
    iWarranty: BASE_STORAGE + '/20190225/5651c50c26ff188c6c9ae246afa39bd2/warranty@2x.png',
  },
  GoldMall: {
    logo: 'https://res.insta360.com/static/3de6537c7ff41c88a95fa4fda7bac599/black-en@3x.png',
    point: 'https://res.insta360.com/static/8dc5705e9ae5626b71d96faca71dc9cd/gold-icon.png',
  },
};

export const ICON = {
  menuMB: BASE_OPERATION + '/0184/48f0c236ec85594e899bb3a5a60680bc/icon-menu_black@2x.png',
  closeBlack: BASE_STORAGE + '/20190615/d24ecc4133d4889f1fb1f6603c999315/i_close.png',
  user: BASE_OPERATION + '/0184/3bafa3a80b9599b095e7c091e2ceb78b/header_ic_account_a@2x.png',
  footerSocial: {
    instagram: BASE_WWW + '/project/official/component/footer/instagram_3%402x.png',
    // youtube: BASE_WWW + '/project/official/component/footer/youtube@2x.png',
    facebook: BASE_WWW + '/project/official/component/footer/facebook@2x.png',
    twitter: BASE_WWW + '/project/official/component/footer/twitter@2x.png',
    wechat: BASE_WWW + '/project/official/component/footer/wechat@2x.png',
    wechatQRCode: {
      default:
        'https://res.insta360.com/static/assets/storage/20210426/4f2aec8b647d90185d66cd85388f079e/%E5%AE%98%E6%96%B9%E6%9C%8D%E5%8A%A1%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg',
    },
    weibo: BASE_WWW + '/project/official/component/footer/weibo@2x.png',
    youku: BASE_WWW + '/project/official/component/footer/youku@2x.png',
  },
};
