import React from 'react';
import { Collapse } from 'antd';
import styles from './index.mobile.module.less';

const expandIcon = 'https://static.insta360.com/assets/storage/20190615/d24ecc4133d4889f1fb1f6603c999315/i_close.png';

const MenuMobile = (props) => {
  const { menusGroup } = props;
  const temp = menusGroup[0];
  // 把第二项与第一项更换位置
  menusGroup[0] = menusGroup[1];
  menusGroup[1] = temp;

  return (
    <div className={styles.footerLinks}>
      <Collapse
        className={styles.collapse}
        accordion
        defaultActiveKey='0'
        bordered={false}
        expandIcon={({ isActive }) => <img src={expandIcon}
                                           data-active={isActive} alt=''/>}
        expandIconPosition='right'
      >
        {menusGroup.map((item, i) => (
          <Collapse.Panel header={item.key} key={i}>
            {item.val.map((link, j) => (
              <div className={styles.link} key={j}>
                <a href={link.url} target='_blank' rel='noopener noreferrer'>
                  {link.key}
                </a>
              </div>
            ))}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default MenuMobile;
