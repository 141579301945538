import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { querySelector } from '@/utils';
import styles from './index.module.less';
import BackTop from './BackTop';
import Intercom from './Intercom';
import dynamic from 'next/dynamic';

const Udesk = dynamic(() => import('./Udesk'), { ssr: false });

const PageToolBar = inject('area', 'app')((props) => {

  const {country:{code}} = props.area
  const {isMobile, G_CurrentPathName} = props.app
  const [toolBarOffset, setToolBarOffset] = useState(false)

  useEffect(async ()=>{
    handleToolOffset();
  },[])

  const handleToolOffset = async () =>{
    if (!isMobile || G_CurrentPathName === '/') return
    let eleIntercom = null
    setToolBarOffset(true)
    if (code !== 'CN'){
      eleIntercom = await querySelector('.intercom-launcher')
      eleIntercom.style.bottom = '100px';
      eleIntercom.style.width = '55px';
      eleIntercom.style.height = '55px';
    }
  }

  return (
    <div id='fixedToolBar' className={styles.toolBar}
         data-offset={toolBarOffset}
         data-c={code}>
      <BackTop/>
      {
        code === 'CN' && <Udesk/>
      }
      {
        code !== 'CN' && <Intercom/>
      }
    </div>
  );
})

export default PageToolBar;
