import { isWechat } from '@/utils';

function getSocialList ({ country }) {
  const CountryCode = country.code;
  const X_Language = country.X_Language;
  const socialPlatform = {
    instagram: {
      title: 'instagram',
      ic: 'https://res.insta360.com/static/cac7a6f87f8938c7fe51a995f8d0d592/ins-black.png',
      icHover: 'https://res.insta360.com/static/03145351556d7486abe53b6440783843/ins-white.png',
      link: '',
      linkGroup: {
        default: 'https://www.instagram.com/insta360/',
        ko_kr: 'https://www.instagram.com/insta360kr/'
      }
    },
    youtube: {
      title: 'Youtube',
      ic: 'https://res.insta360.com/static/e052595aed30ec8645b30344e093aaf4/ytb-black.png',
      icHover: 'https://res.insta360.com/static/7554c80b8818435ed092d3a5c5537174/ytb-white.png',
      link: '',
      linkGroup: {
        default: 'https://www.youtube.com/insta360',
        ja_jp: 'https://www.youtube.com/c/Insta360Japan'
      }
    },
    facebook: {
      title: 'Facebook',
      ic: 'https://res.insta360.com/static/dfa09cf49e1fef9c16e8034e611e300d/fb-black.png',
      icHover: 'https://res.insta360.com/static/a04ee0783873f9b854dd3991602154f4/fb-white.png',
      link: '',
      linkGroup: {
        default: 'https://www.facebook.com/Insta360/',
        ko_kr: 'https://www.facebook.com/Insta360Korea/',
        ja_jp: 'https://www.facebook.com/Insta360Japan',
        fr_fr: 'https://www.facebook.com/Insta360France',
        zh_tw: 'https://www.facebook.com/Insta360Apac',
        es_es: 'https://www.facebook.com/Insta360Spain',
        it_it: 'https://www.facebook.com/Insta360Italiano',
        de_de: 'https://www.facebook.com/Insta360Germany/'
      }
    },
    twitter: {
      title: 'Twitter',
      ic: 'https://res.insta360.com/static/5c37925ff6d9fce6e8d83bf7778abeb2/twitter-black.png',
      icHover: 'https://res.insta360.com/static/c90943daf65e1417e31b335d6359030f/twitter-white.png',
      link: '',
      linkGroup: {
        default: 'https://twitter.com/insta360',
        ja_jp: 'https://twitter.com/Insta360Japan'
      }
    },
    tiktok: {
      title: 'Tiktok',
      ic: 'https://res.insta360.com/static/f60147c1b1789b030e403f7f068530d8/tiktok_black.png',
      icHover: 'https://res.insta360.com/static/e39621cf1fc43d2fbcba3074bbddbe25/tiktok_white.png',
      link: '',
      linkGroup: {
        default: 'https://www.tiktok.com/@insta360_official'
      }
    },
    naver: {
      title: 'naver',
      ic: 'https://res.insta360.com/static/a7a6f60f8ce3b927d8a31af149e58c4f/naver-black.png',
      icHover: 'https://res.insta360.com/static/049e31c20b81a82149036a3dc9b94dc6/naver-white.png',
      link: '',
      linkGroup: {
        ko_kr: 'https://blog.naver.com/insta360'
      }
    },
    wechat: {
      title: '微信',
      ic: 'https://res.insta360.com/static/c88f6624b3be9646c2c7b53d4ac37177/wechat_black.png',
      icHover: 'https://res.insta360.com/static/2b94c7bfdf603fbac81aebb567856490/wechat_white.png',
      link: {
        default: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA5ODIxOTg0Mw==&scene=124#wechat_redirect'
      },
      popupImg: {
        default: 'https://static.insta360.com/assets/storage/20191101/ad532c15c4879b968f1acb2ca80572db/qrcode_wechat.png'
      }
    },
    weibo: {
      title: '微博',
      ic: 'https://res.insta360.com/static/12ead21da1067a50c1e82e0659a8eba2/weibo-black.png',
      icHover: 'https://res.insta360.com/static/e6d7b499d0c33f56202104e9362858df/weibo-white.png',
      link: 'http://weibo.com/insta360'
    },
    bilibili: {
      title: 'B站',
      ic: 'https://res.insta360.com/static/810f4ad99d436fefdc6ab3a6a51702f1/bili-black.png',
      icHover: 'https://res.insta360.com/static/373dbff9b6781e14398fbdc082c692e1/bili-white.png',
      link: 'https://space.bilibili.com/39279965'
    },
    douYin: {
      title: '抖音',
      ic: 'https://res.insta360.com/static/f60147c1b1789b030e403f7f068530d8/tiktok_black.png',
      icHover: 'https://res.insta360.com/static/e39621cf1fc43d2fbcba3074bbddbe25/tiktok_white.png',
      link: 'https://v.douyin.com/dkxN1BS/'
    },
    kuaishou: {
      title: '快手',
      ic: 'https://res.insta360.com/static/f8942aee783368c5d6ebf85d2dbb7c2e/kuaishou-black.png',
      icHover: 'https://res.insta360.com/static/304ab7e37a1c2fc54f01be63822ebacd/kuaishou-white.png',
      link: 'https://v.kuaishou.com/cLH6lE'
    }
  };

  // 海外社媒
  const getListOversea = () => {
    Object.keys(socialPlatform).forEach((key) => {
      const cur = socialPlatform[key];
      if (cur.linkGroup) {
        cur.link = cur.linkGroup[X_Language] ?? cur.linkGroup['default'];
      }
    });
    return [
      socialPlatform.instagram,
      socialPlatform.youtube,
      socialPlatform.facebook,
      CountryCode === 'KR' ? socialPlatform.naver : socialPlatform.twitter,
      socialPlatform.tiktok
    ];
  };

  // 大陆社媒
  const getListChina = () => {

    Object.keys(socialPlatform).forEach((key) => {
      if (key === 'wechat') {
        const cur = socialPlatform[key];
        cur.link = isWechat() ? cur.link['default'] : '';
        cur.popupImg = isWechat()
          ? ''
          : cur.popupImg['default'];
      }
    });

    return [
      socialPlatform.wechat,
      socialPlatform.weibo,
      socialPlatform.bilibili,
      socialPlatform.douYin,
      socialPlatform.kuaishou
    ];
  };

  return CountryCode === 'CN' ? getListChina() : getListOversea();
}

export { getSocialList };
