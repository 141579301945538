import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import PageToolBar from '@/components/PageToolBar';
import Loading from '@/components/SpinLoading';
import AreaSelectorModal from '@/components/AreaSelectorModal';
import AreaSelectorConfirmModal from '@/components/AreaSelectorConfirmModal';
import styles from './index.module.less';

const Layout = inject('area', 'app')(observer(props => {
  const { initListCountry } = props.area;
  const { G_GlobalLoading } = props.app;
  useEffect(() => {
    initListCountry();
  }, []);
  return (
    <div className={styles.page}>
      <Header/>
      <div id='g-main' className={styles.main}>
        {props.children}
      </div>
      <Footer/>

      {/* 移动端地区确认 */}
      <AreaSelectorConfirmModal/>

      {/* 桌面端地区选择 */}
      <AreaSelectorModal/>

      {/* 页面右下角浮动工具栏 */}
      <PageToolBar/>

      {G_GlobalLoading && <Loading className='global-spin-loading'/>}
    </div>
  );
}));

export default Layout;
