import sha1 from 'crypto-js/sha1';
import { isBrowser } from '@/utils/index';

export default function bootUdesk(email) {
  const nonce = 'insta360';
  const timestamp = new Date().getTime();
  const web_token = email;

  const sign_str = `nonce=${nonce}&timestamp=${timestamp}&web_token=${web_token}&e55abffbf1fedcf1a1cbd94b8a67ded4`;
  const signature = sha1(sign_str).toString().toUpperCase();

  console.log('bootUdesk：', email);

  if (isBrowser){
    window.ud &&
    window.ud({
      customer: { nonce, signature, timestamp, web_token },
    });
  }
}
