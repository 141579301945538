import { action, observable, makeObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { cache, handleQueryParams } from '@/utils';
import StoreBase from './Base';

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined');

class Area extends StoreBase {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable
  listCountryInfos = {};

  @observable
  areaMap = [];

  @observable
  allCountry = [];

  @observable
  country = {};

  @action
  setStateValue = (state, value) => {
    this[state] = value;
  };

  @observable
  areaModalVisible = false;

  @action
  toggleAreaModal = (areaModalVisible) => {
    this.areaModalVisible = typeof areaModalVisible !== 'object' ? areaModalVisible : !this.areaModalVisible;
  };

  @observable
  LANG = {};

  @action
  initListCountry = () => {
    const dataMap = this.listCountryInfos;
    let areaMap = [];
    let allCountry = [];
    for (const key in dataMap) {
      if (Object.prototype.hasOwnProperty.call(dataMap, key)) {
        areaMap.push({
          name: key,
          list: dataMap[key],
        });
      }
      if (key !== 'COMMON') {
        allCountry.push(...dataMap[key]);
      }
    }
    allCountry = allCountry.sort((prev, next) => (prev.code > next.code ? 1 : -1));
    this.areaMap = areaMap;
    this.allCountry = allCountry;
  }

  @action
  setCountry = (country) => {
    country.language = country.language.toLowerCase();
    const { code, currency, currency_signal, language, language_text, name } = country;
    window.history.pushState({}, null, handleQueryParams('X-Country'));
    cache.setCookie('X_Country_Code_11', encodeURIComponent(code));
    cache.setCookie('X_Language_11', encodeURIComponent(language));
    cache.setCookie('X_Currency_11', encodeURIComponent(currency));
    cache.setCookie('X_Currency_Signal_11', encodeURIComponent(currency_signal));
    cache.setCookie('X_Language_Text_11', encodeURIComponent(language_text));
    cache.setCookie('X_Country_Name_11', encodeURIComponent(name));
    // 避免字符编码导致异常，所以Encode，读取再Decode
    window.location.reload();
  };
}

export default Area;
