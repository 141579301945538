import { action, observable, makeObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import StoreBase from './Base';

enableStaticRendering(typeof window === 'undefined');

class App extends StoreBase {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable
  isMobile = false;

  @observable
  isProd = false; //是否生产环境

  @observable
  isTest = false; //是否生产测试环境

  // 是否支持webp
  @observable
  webp = false;

  // url参数
  @observable
  urlParams = {};

  // 是否显示全局蒙层Loading
  @observable
  G_GlobalLoading = false;

  // 当前页面路由
  @observable
  G_CurrentPathName = typeof window !== 'undefined' ? window.location.pathname.toLowerCase() : '/';

  @action
  setGlobalValue = (key, value) => {
    this[key] = value;
  };
}

export default App;
