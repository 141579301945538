import { action } from 'mobx';

class StoreBase {
  constructor (root) {
    this.root = root
  }

  @action hydrate = (data) => {
    if (!data) return
    try {
      const keys = Object.keys(data)
      keys.forEach(key=>{
        this[key] = data[key]
      })
    }catch (e){
      console.log(e)
    }
  }
}

export default StoreBase
