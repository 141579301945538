import { Provider } from 'mobx-react';
import { rootStoreHandle } from '@/mobx';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Layout from '@/components/Layout';
import getMeta from '@/components/Layout/Meta/handler';
const NotAvailablePage = dynamic(()=>import('@/components/NotAvailablePage'),{ssr:false})

require('src/styles/base.less');

function deepCloneAssign(origin = {}, ...args) {
  for (let i in args) {
    for (let key in args[i]) {
      if (args[i].hasOwnProperty(key)) {
        origin[key] =
          args[i][key] && typeof args[i][key] === 'object'
            ? deepCloneAssign(Array.isArray(args[i][key]) ? [] : {}, origin[key], args[i][key])
            : args[i][key];
      }
    }
  }
  return origin;
}

function handleInitialState({ props }) {
  const { pageProps, localeData, country, isMobile, webp, isProd, isTest, RouteConfig = {}, pageCommonState } = props;
  const pageInitialState = pageProps?.initialState || {};
  const baseState = {
    app: {
      isMobile,
      webp,
      isProd,
      isTest,
      urlParams: RouteConfig.urlParams,
    },
    area: {
      LANG: localeData,
      country,
    },
  };
  let state = deepCloneAssign(baseState, pageInitialState);
  state = deepCloneAssign(state, pageCommonState);
  return state;
}

const SafeHydrate = ({ props }) =>{
  const { Component, pageProps, enableSSR } = props;
  // 客户端渲染
  if (process.browser) {
    return <Component {...pageProps} />
  }
  // 开启ssr，服务端渲染
  if (!process.browser && enableSSR) {
    return <Component {...pageProps} />
  }
  // 开启ssr配置，服务端不渲染
  if (!process.browser && !enableSSR){
    return null
  }
}

const App = (props) => {
  const { originalUrl, country, RouteConfig } = props;

  const initialState = handleInitialState({ props });

  const rootStore = rootStoreHandle({ initialState });

  const isRouteNotAvailable = RouteConfig?.isNotAvailable

  const meta = getMeta({originalUrl, country})
  return (
    <Provider {...rootStore}>
      <Head>
        <title>{meta.title}</title>
        <meta name='viewport' content="width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=1.0, viewport-fit=cover"/>
      </Head>
      <Layout>
        {
          isRouteNotAvailable && <NotAvailablePage/> // 地区不支持的页面显示该信息
        }
        {
          !isRouteNotAvailable && <div suppressHydrationWarning> <SafeHydrate props={props}/> </div>
        }
      </Layout>
    </Provider>
  );
};

App.getInitialProps = async ({ ctx }) => {
  if (!ctx.res?.inject) return {};
  const inject = ctx.res?.inject
  return {
    ...inject,
    localeData: ctx.res.localeData, // 文案
    originalUrl: ctx.req.originalUrl, // url路径
    pageCommonState: ctx.res.pageData.commonState || {}
  };
};

export default App;
