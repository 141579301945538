import React from 'react';
import { getSocialList } from '@/components/Footer/components/Social/const/data.config';
import { ClassNames } from '@/utils';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';

const SocialMob = ({ area }) => {
  const { country } = area;
  return (
    <div className={styles.socialWrap}>
      <div className={styles.social}>
        {getSocialList({ country }).map((social, key) => (
          <a
            className={styles.item}
            href={social.link}
            key={key}
            target={`${social.link ? '_blank' : ''}`}
            rel='noopener noreferrer'
          >
            <div className={ClassNames([styles.imgWrap])} style={{ backgroundImage: `url("${social.ic}")` }} />
            {social.popupImg && (
              <div className={styles.qrWrap}>
                <img className={styles.icon} src={social.popupImg} alt='' />
              </div>
            )}
          </a>
        ))}
      </div>
    </div>
  );
};

export default inject('area', 'app')(observer((props) => <SocialMob {...props} />));
