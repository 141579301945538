import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ICON } from '@/utils/configs/static.config';
import styles from './index.module.less';
import Subscribe from './components/Subscribe';
import Social from './components/Social';
import SocialListMob from './components/Social/index.mobile';
import Menus from './components/Menus/index';
import MenusMob from './components/Menus/index.mobile';
import Copyright from './components/Copyright/index';

@inject('area', 'app')
@observer
class Index extends Component {
  getMenusGroup = () => {
    const {
      LANG,
      country: { code, X_Language },
    } = this.props.area;
    let linksGroup = [];
    try {
      linksGroup = JSON.parse(LANG['footer.title']);
    } catch {}
    linksGroup.forEach((itemGroup, i) => {
      let group = [];
      try {
        group = JSON.parse(LANG[`footer.links${i + 1}`]);
      } catch {}
      const arr = [];

      group.forEach((o, j) => {
        if (i === 2 && j === 4) {
          return;
        }
        if (i === 1 && j === 0) {
          if (['US', 'GB', 'CA', 'AU', 'HK', 'ES'].includes(code)) {
            const key = {
              en_us: 'Student Discounts',
              zh_tw: '學生特惠',
              es_es: 'Descuento de estudiante',
            };
            arr.push({
              key: key[X_Language],
              url: '/studentdiscounts',
            });
          }
        }
        if (o.url === '/tradeup') {
          if (['CN', 'US', 'JP'].includes(code)) {
            arr.push({
              key: o.key,
              url: o.url,
            });
          }
        } else {
          arr.push({
            key: o.key,
            url: o.url,
          });
        }
      });
      itemGroup.val = arr;
    });
    return linksGroup;
  };

  render() {
    const { isMobile } = this.props.app;
    const {
      country: { code },
      LANG,
    } = this.props.area;
    const menusGroup = this.getMenusGroup();
    const contact = [
      {
        key: LANG['common.label.serviceEmail'] + ':',
        val: <a href='mailto:ecommerce@insta360.com'>ecommerce@insta360.com</a>,
      },
      {
        key: LANG['footer.workDayKey'],
        val: LANG['footer.workDayVal'],
      },
      {
        key: LANG['footer.addressKey'],
        val: [
          'AT',
          'DE',
          'BE',
          'BG',
          'CY',
          'CZ',
          'DK',
          'EE',
          'FI',
          'GR',
          'CH',
          'GB',
          'HR',
          'HU',
          'IE',
          'LI',
          'LT',
          'LU',
          'LV',
          'MT',
          'NL',
          'NO',
          'PL',
          'PT',
          'RO',
          'SE',
          'SI',
          'SK',
          'ES',
          'FR',
          'IT',
          'IS',
          'AD',
          'MC',
          'RU',
        ].includes(code)
          ? 'Insta360 GmbH, Gartenfelder Str. 29-37, 13599 Berlin, Germany（Germany office）'
          : LANG['footer.addressVal'],
      },
    ];
    return (
      <div id='g-footer' className={styles.footer}>
        <Subscribe />

        <div className={styles.wrap}>
          <Social />

          <div className={styles.menusWrap}>
            {isMobile && <MenusMob menusGroup={menusGroup} />}
            {!isMobile && <Menus menusGroup={menusGroup} />}
            <div className={styles.contact}>
              <ul>
                {contact.map(
                  (o, k) =>
                    o.key && (
                      <li key={k}>
                        <div className={styles.k}>{o.key}</div>
                        <div className={styles.v}>{o.val}</div>
                      </li>
                    )
                )}
              </ul>
              {code === 'CN' && (
                <img className={styles.qrcode} src={ICON.footerSocial.wechatQRCode['default']} alt='' />
              )}
            </div>
          </div>

          {isMobile && <SocialListMob />}

          <Copyright />
        </div>
      </div>
    );
  }
}

export default Index;
