import { action,observable, makeObservable } from 'mobx';
import { httpRequest } from '@/request';
import StoreBase from './Base';
import { enableStaticRendering } from 'mobx-react';
import {getServerUrl} from '@/request/config';

enableStaticRendering(typeof window === 'undefined');

class State extends StoreBase {
  constructor (props) {
    super(props);
    makeObservable(this);
  }

  // 用户积分
  @observable
  userPoint = null

  // 获取用户积分
  @action
  getTotalPoints = () => {
    httpRequest({
      url: getServerUrl('CREDIT', '/account/getTotalPoints', this.root.app),
      instaToken: true,
      country: this.root.area.country
    }).then(({data})=>{
      this.userPoint = data?.total_points ?? 0
    })
  };

  // 兑换积分商品
  @action
  exChangeProduct = (product_id) => {
    return httpRequest({
      url: getServerUrl('CREDIT', '/product/exchange', this.root.app),
      method: 'post',
      instaToken: true,
      country: this.root.area.country,
      data: {
        product_id
      }
    })
  };

  // 兑换第三方卡券
  @action
  exChangeThirdGiftCard = (data) => {
    return httpRequest({
      url: getServerUrl('CREDIT', '/product/card/exchange', this.root.app),
      method: 'post',
      instaToken: true,
      country: this.root.area.country,
      data
    })
  };

  // 兑换次数查询
  @action
  getProductExchangeTimes = ( product_id ) =>{
    return new Promise(resolve => {
      httpRequest({
        url: getServerUrl('CREDIT', '/product/card/getExchangeCardTimes', this.root.app),
        instaToken: true,
        country: this.root.area.country,
        params: { product_id }
      }).then(({code,data})=>{
        if (code === 0){
          resolve(data?.exchange_time)
          return
        }
        resolve(-1)
      }).catch(()=>resolve(-1))
    })
  }
}

export default State;
